import React from 'react';
import { Router, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { components as loginComponents, screens as loginScreens } from '../login';
import { screens as homeScreens } from '../home';
import history from './history';
import { isBootstraped } from './selectors';
import { ZWebAuthenticated } from '../../services';
import {Redirect} from "react-router";

const styles = theme => ({
    background: {
        width: '100%',
        height: '100%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: theme.palette.custom.backgroundSmoke
    }
});

const MainRouter = ({ ready, classes }) => {
    if (!ready) {
        return (<div className={classes.background}><ZWebAuthenticated.components.Spinner /></div>);
    }
    return (
        <Router history={history}>
            <Switch style={{ width: '100%', height: '100%' }}>
                <loginComponents.UnloggedRoute path="/login" exact component={loginScreens.LoginScreen} />
                <loginComponents.LoggedRoute path="/select-entity" exact component={loginScreens.EntitySelectorScreen} />
                <loginComponents.PrivateRoute path="/" exact component={homeScreens.HomeScreen} />
                <Redirect from="*" to="/"/>
            </Switch>
        </Router>
    );
}

const mapStateToProps = (state) => ({ ready: isBootstraped(state) });
export default connect(mapStateToProps)(withStyles(styles)(MainRouter));