import React, {useCallback} from 'react';
import {ZToolbar} from "@zetti/zweb-react-commons";
import {Box, createStyles, IconButton, makeStyles} from "@material-ui/core";
import {ZWebAuthenticated} from '../../../services'
import {useDispatch} from "react-redux";
import LogoZetti from './LogoZetti'

const useStyles = makeStyles(() =>
  createStyles({
    menuLogo: {
      marginLeft: -12,
      marginRight: 10,
      color: 'white', 
      fontWeight: 'bold'
    },
    content: {
      display: "flex",
      flexGrow: 1,
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  })
);

/* const useOpenAboutPopup = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(ZWebAuthenticated.actions.openAboutPopup());
  }, []);
}; */

const LoggedAppBar = () => {

  const classes = useStyles();

 // const openAboutPopup = useOpenAboutPopup();

  return (
    <ZToolbar
    appBarProps={{
      style: {
        color: '#ffffff',
        boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important"
    }
    }}
    >
      {/* <div className={classes.content}>
        <span className={classes.menuLogo}>T&Sweb</span>
       <LogoZetti />
      </div> */}
    </ZToolbar>
  );
}

export default LoggedAppBar;


