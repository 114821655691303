const styles = theme => ({
    paper: {
      width: '100%',
      userSelect: 'none',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
    },
    ListItem: {
        cursor: 'pointer'
    }
  });

  export default styles;