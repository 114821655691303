import React from 'react';
import {PublicContainer} from '../../layout';
import LoginForm from '../components/LoginForm';
import Grid from '@material-ui/core/Grid';
import LoginBox from '../components/LoginBox';
import {BuildData} from '../../../services';

const styles = {
  appVersion: {
    fontSize: 'small',
    color: 'grey',
    fontStyle: 'normal',
    maxWidth: '500px',
    width: '100%',
    textAlign: 'right',
    paddingTop: 10
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 30
  },
  buildDate: {
    marginLeft: '5px',
    color: '#b6b7b7'
  }
}
const LoginScreen = () => {
  return (
    <PublicContainer>
      <Grid container justify="center" style={{paddingBottom: 40, backgroundColor: '#EEEEEE'}}>
        <Grid item xs={12}>
          <div style={styles.boxContainer}>
            <LoginBox>
              <LoginForm/>
            </LoginBox>
            <div style={styles.appVersion}><span>{BuildData.version}</span> <span
              style={styles.buildDate}>{BuildData.buildDate}</span></div>
          </div>
        </Grid>
      </Grid>
    </PublicContainer>
  );
};

export default LoginScreen;


