const reports = [
    {
      title: 'Importación de los últimos 3 días',
      name: 'LAST_DAYS_IMPORTS',
      items: [
        {
          name: 'HTML',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_estado_importacion&estadoImportacion=0&tipo=html&fecDesde=#FECHA_DESDE&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'PDF',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_estado_importacion&estadoImportacion=0&tipo=pdf&fecDesde=#FECHA_DESDE&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'CSV',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_estado_importacion&estadoImportacion=0&tipo=csv&fecDesde=#FECHA_DESDE&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'XLS',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_estado_importacion&estadoImportacion=0&tipo=xls&fecDesde=#FECHA_DESDE&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
      ]
    },
    {
      title: 'Vencimientos de pagos a Proveedores',
      name: 'EXPIRATION_PROVIDERS',
      items: [
        {
          name: 'HTML',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=html&codigoJerarquico=#CODIGOJERARQUICO&corte=false&detalle=true&orden=tipo_entidad,%20entidad,%20fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=2&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'PDF',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=pdf&codigoJerarquico=#CODIGOJERARQUICO&corte=false&detalle=true&orden=tipo_entidad,%20entidad,%20fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=2&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'CSV',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=csv&codigoJerarquico=#CODIGOJERARQUICO&corte=false&detalle=true&orden=tipo_entidad,%20entidad,%20fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=2&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'XLS',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=xls&codigoJerarquico=#CODIGOJERARQUICO&corte=false&detalle=true&orden=tipo_entidad,%20entidad,%20fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=2&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
      ]
    },
    {
      title: 'Vencimientos de pagos de Servicios',
      name: 'EXPIRATION_SERVICES_PAYMENTS',
      items: [
        {
          name: 'HTML',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=html&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=27&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'PDF',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=pdf&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=27&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'CSV',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=csv&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=27&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'XLS',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=xls&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=27&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
      ]
    },
    {
      title: 'Vencimientos de pagos de Gastos Varios',
      name: 'EXPIRATION_PERIODIC_PAYMENTS',
      items: [
        {
          name: 'HTML',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=html&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'PDF',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=pdf&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'CSV',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=csv&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'XLS',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_documentos_pendientes&tipo=xls&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=true&orden=tipo_entidad, entidad, fecha_vencimiento&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
      ]
    },
    {
      title: 'Vencimientos de Cheques Propios',
      name: 'EXPIRATION_CHEQUE_PAYMENTS',
      items: [
        {
          name: 'HTML',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_seguimiento_cheque&tipo=html&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=false&orden=fecha_vencimiento, banco.nombre&echeque_id=43&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'PDF',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_seguimiento_cheque&tipo=pdf&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=false&orden=fecha_vencimiento, banco.nombre&echeque_id=43&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'CSV',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_seguimiento_cheque&tipo=csv&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=false&orden=fecha_vencimiento, banco.nombre&echeque_id=43&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
        {
          name: 'XLS',
          url: '../../ftweb/loginOAuth?servlet=reportes&xml=rpt_seguimiento_cheque&tipo=xls&codigoJerarquico=#CODIGO_JERARQUICO&corte=false&detalle=false&orden=fecha_vencimiento, banco.nombre&echeque_id=43&fecVHasta=#FECHA_VENCIMIENTO_HASTA&tipo_entidad_id=29&node=#NODO_NAME&nodo_id=#NODO_ID&nodoSesionId=#NODOSESIONID&usuarioSesionId=USUARIOSESIONID'
        },
      ]
    },
  ];

  export default reports;
