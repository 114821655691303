const styles = theme => ({
    paper: {
      width: '100%',
      userSelect: 'none',
      marginRight: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      marginTop: '40px',
    },
  });

  export default styles;