import React from 'react'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import { compose } from 'recompose'
import { ZWebAuthenticated } from '../../../../services'
import t from './translations'
import styles from './styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


const composer = compose(
  ZWebAuthenticated.hocs.WithUserLoggedProp,
  ZWebAuthenticated.hocs.WithEntitySelectedProp
)

const infoBoxStyles ={
  contactIcon: {
    width: '20px',
    marginLeft: '15px',
    marginBottom: '3px'
  },
  infoLink: {
    cursor: 'pointer',
    color: "#5164e0",
    fontWeight: "bold"
  }
}

const WPP_LINK = 'https://wa.me/5491132537696'
const ZETTI_COURSES_LINK = 'https://zetti.com.ar/cursos/'
const ZETTI_VIDEO_TUTORIALS_LINK = "https://zetti.com.ar/videostutoriales"

const InfoBox = ({ userLogged, entitySelected, classes }) => {
  return (
    <Paper className={classes.paper}>
      <Card style={{paddingTop: '5px', marginLeft: '7%', boxShadow: 'none', justifyItems: 'center'}}>
        <CardContent style={{border: '0px !important', boxShadow: '0'}} >
      <div>{t('support')}  
      <img src={process.env.PUBLIC_URL + '/images/icono_telefono.png'} style={infoBoxStyles.contactIcon}/><strong > 0810-345-1410</strong>
      <span onClick={()=>window.open(WPP_LINK, '_blank')} style={infoBoxStyles.infoLink}><img src={process.env.PUBLIC_URL + '/images/icono_wp.png'} style={infoBoxStyles.contactIcon}/> 11-3253-7696</span>
      </div>
      <div>{t('next_courses')}: <span style={infoBoxStyles.infoLink} onClick={()=>window.open(ZETTI_COURSES_LINK, '_blank')}> zetti.com.ar/cursos</span></div>
      <div>{t('video_tutorials')}: <span style={infoBoxStyles.infoLink} onClick={()=>window.open(ZETTI_VIDEO_TUTORIALS_LINK, '_blank')}> zetti.com.ar/videostutoriales</span></div>
      </CardContent>
      </Card>
    </Paper>
  )
}

export default withStyles(styles)(composer(ZWebAuthenticated.hocs.WithTranslationRerender(InfoBox)))
