
export default () => {
    return {
        FormContainer: {
            textAlign: 'center',
            padding: 20
        },
        TextInput: {
            marginTop: 14,
            width: '100%'
        },
        SubmitBtnContainer: {
            padding: 15
        },
        SubmitBtn: {
        },
        errorContainer: {
            marginTop: 5
        }
    };
}