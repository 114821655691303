export interface ZwebLinks {
    path: string;
    moduleName: string;
}

export const ZWEB_LINKS : ZwebLinks[] = [
    {
        path: "/tysweb/purchases/#/wizard",
        moduleName: "1.1 Compras",
    },
    {
        path: "/tysweb/accounting/#/caea",
        moduleName: "5.7.10.1 CAEA",
    },
    {
        path: "/tysweb/data-management/#/products-groups",
        moduleName: "7.1.10 Cambio global de atributos",
    }
]