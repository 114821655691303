import React from 'react'
import moment from 'moment-with-locales-es6'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import Avatar from '@material-ui/core/Avatar'
import GetAppIcon from '@material-ui/icons/GetApp'
import FolderIcon from '@material-ui/icons/Folder'
import { compose } from 'recompose'
import { ZWebAuthenticated } from '../../../../services'
import t from './translations'
import reports from './Reports'
import styles from './styles'

const composer = compose(
  ZWebAuthenticated.hocs.WithUserLoggedProp,
  ZWebAuthenticated.hocs.WithEntitySelectedProp
)

const renderDownloads = (items, entidad, classes) => {
  return items.map((item, index) => {
    let nodoId = entidad ? entidad.id : null
    let nodoName = entidad ? entidad.nombre : null
    let codigoJerarquico = entidad ? entidad.codigoJerarquico : null
    return (
      <ZWebAuthenticated.components.ListItem
        button
        className={classes.ListItem}
        key={index}
        onClick={(event) => { window.open(item.url.replace('#NODO_NAME', nodoName).replace('#NODO_ID', nodoId).replace('#FECHA_DESDE', moment().subtract(3, 'days').format('DD/MM/YYYY')).replace('#FECHA_VENCIMIENTO_HASTA', moment().add(5, 'days').format('DD/MM/YYYY')).replace('#CODIGO_JERARQUICO', codigoJerarquico).replace('#NODOSESIONID', nodoId) + '&access_token=' + JSON.parse(localStorage.getItem('zweb-auth-common|AUTH|authData')).accessToken, '_blank') }}
      >
        <ListItemText primary={item.name}/>
        <ListItemAvatar>
          <Avatar><GetAppIcon/></Avatar>
        </ListItemAvatar>
      </ZWebAuthenticated.components.ListItem>
    )
  })
}

const renderReports = (reports, entidad, classes) => {
  return reports.map((report, index) => {
    return <ZWebAuthenticated.components.CollapsableListItem
      iconComponent={<Avatar><FolderIcon/></Avatar>}
      text={t(`reports.${report.name}`, { plainText: true })}
      secondaryText={report.items.length + t('availableFiles', { plainText: true })}
      key={index}
    >
      {renderDownloads(report.items, entidad, classes)}
    </ZWebAuthenticated.components.CollapsableListItem>
  })

}

const ReportList = ({ userLogged, entitySelected, classes }) => {
  if (!userLogged || !entitySelected) {
    return <div
      style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <ZWebAuthenticated.components.Spinner styles={{ flex: 0 }} size={16}/>
    </div>
  }
  return (
    <Paper className={classes.paper}>
      <List
        component="nav"
        subheader={<ListSubheader disableSticky={true} component="div">{t('title')}</ListSubheader>}
        className={classes.root}
      >
        {renderReports(reports, entitySelected, classes)}
      </List>
    </Paper>
  )
}

export default withStyles(styles)(composer(ZWebAuthenticated.hocs.WithTranslationRerender(ReportList)))
