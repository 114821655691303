import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('LoginFormComponent', {
    es: {
        username: {
            placeholder: 'Ingrese su nombre de usuario',
            label: 'Nombre de Usuario'
        },
        password: {
            placeholder: 'Ingrese su contraseña',
            label: 'Contraseña'
        },
        loginBtn: 'INGRESAR',
        wrongCredentials: 'Usuario o password incorrectos. verifíquelos.'
    },
    pt: {
        username: {
            placeholder: 'Digite seu nome de usuário',
            label: 'Nome de usuário'
        },
        password: {
            placeholder: 'Digite sua senha',
            label: 'Senha'
        },
        loginBtn: 'ENTER',
        wrongCredentials: 'Nome de usuário ou senha incorretos. verifique eles.'
    },
    en: {
        username: {
            placeholder: 'Enter your username',
            label: 'Username'
        },
        password: {
            placeholder: 'Enter your password',
            label: 'Password'
        },
        loginBtn: 'LOGIN',
        wrongCredentials: 'Incorrect username or password. check them out.'
    }
});

