import React from "react";
import { PublicContainer } from "../../layout";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/styles";
import LoginBox from "../components/LoginBox";
import EntitySelector from "../components/EntitySelector";
import { connect } from "react-redux";
import { ZWebAuthenticated } from "../../../services";

const EntitySelectorScreen = () => {
  return (
    <PublicContainer>
      <Grid container justify="center" style={{paddingBottom: 40}}>
        <Grid item xs={12}>
          <LoginBox>
            <EntitySelector />
          </LoginBox>
        </Grid>
      </Grid>
    </PublicContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(ZWebAuthenticated.actions.logout())
});
export default connect(
  null,
  mapDispatchToProps
)(EntitySelectorScreen);
