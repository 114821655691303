import { connect } from 'react-redux';
import LoginFormComponent from './LoginFormComponent';
import {ZWebAuthenticated} from '../../../../services';
import actions from '../../actions';

const mapStateToProps = (state) => {
    return {
        loading: ZWebAuthenticated.selectors.isLoading(state),
        loginError: ZWebAuthenticated.selectors.loginError(state)
    } 
};

const mapDispatchToProps = dispatch => {
    return {
        login: (username, password, redirectTo) => dispatch(actions.login(username, password, redirectTo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginFormComponent);
