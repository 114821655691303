import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import styles from './styles';
import HistoryIcon from '@material-ui/icons/History';
import { ZWebAuthenticated } from '../../../../services';
import { compose } from 'recompose';
import moment from 'moment-with-locales-es6';
import t from './translations';
import { get } from "lodash";

const composer = compose(
  ZWebAuthenticated.hocs.WithOpenNodeHandler,
  ZWebAuthenticated.hocs.WithHistoryCollection({ collectionName: ZWebAuthenticated.constants.HISTORY_COLLECTION_NODES_NAME, propName: 'nodes' })
);

const OpenedNodesHistoryList = ({ nodes, classes, onOpenNode }) => {

  const [historyNodes, setHistoryNodes] = useState(undefined);

  const handleMouseDown = (e, node) => {
    const nativeEvent = get(e, "nativeEvent.which");
    const inNewTab = nativeEvent === 2;
    if (inNewTab) {
      onOpenNode(node, inNewTab);
    }
  }

  useEffect(() => {
    const sevenItems = _.slice(nodes, [0], [7])
    setHistoryNodes(sevenItems)
  }, [nodes]);

  return (
    <Paper className={classes.paper}>
      <List
        component="nav"
        subheader={<ListSubheader disableSticky={true} component="div" >
          <HistoryIcon style={{ color: '#CCC', position: 'relative', top: 6, marginRight: 10 }} />
          {t('title')}
        </ListSubheader>}
        className={classes.root}
      >
        {historyNodes?.map((node, index) => {
          const usedAt = moment(node._usedAt).locale('es').fromNow();
          return (
            <ZWebAuthenticated.components.ListItem
              button
              className={classes.ListItem}
              key={index}
              onClick={(e) => {
                onOpenNode(node, e.ctrlKey)
              }}
              onMouseDown={e => {
                handleMouseDown(e, node)
              }}
            >
              <ListItemText primary={node.name} secondary={usedAt} />
            </ZWebAuthenticated.components.ListItem>
          )
        })}
      </List>
    </Paper>
  )
}

export default withStyles(styles)(composer(OpenedNodesHistoryList));
