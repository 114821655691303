import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#00adee',
            contrastText: "#ffffff",
            light: "#ffffff"
        },
        secondary: {
            main: '#ffffff'
        },
        custom: {
            black: 'rgba(0, 0, 0, 0.87)',
            green: '#adc94e',
            backgroundSmoke: '#EEEEEE',
            errorColor: '#f1956f'
        }
    },
    customBars: {
        drawerWidth: 450
    }
});

export default theme;