import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('ReportList', {
    es: {
        title: 'Reportes',
        availableFiles: ' archivos disponibles',
        reports: {
            LAST_DAYS_IMPORTS: 'Importación de los últimos 3 días',
            EXPIRATION_PROVIDERS: 'Vencimientos de pagos a Proveedores',
            EXPIRATION_SERVICES_PAYMENTS: 'Vencimientos de pagos de Servicios',
            EXPIRATION_PERIODIC_PAYMENTS: 'Vencimientos de pagos de Gastos Varios',
            EXPIRATION_CHEQUE_PAYMENTS: 'Vencimientos de Cheques Propios'
        }
    },
    pt: {
        title: 'Relatórios',
        availableFiles: ' arquivos disponíveis',
        reports: {
            LAST_DAYS_IMPORTS: 'Importação dos últimos 3 dias.',
            EXPIRATION_PROVIDERS: 'Expiração de pagamentos a fornecedores.',
            EXPIRATION_SERVICES_PAYMENTS: 'Expiração de pagamentos de serviços.',
            EXPIRATION_PERIODIC_PAYMENTS: 'Expiração de pagamentos de despesas diversas.',
            EXPIRATION_CHEQUE_PAYMENTS: 'Expiração de cheques próprios'

        },        
    },
    en: {
        title: 'Reports',
        availableFiles: ' available files',
        reports: {
            LAST_DAYS_IMPORTS: 'Import of the last 3 days.',
            EXPIRATION_PROVIDERS: 'Expiration of payments to Suppliers.',
            EXPIRATION_SERVICES_PAYMENTS: 'Expiration of Services payments.',
            EXPIRATION_PERIODIC_PAYMENTS: 'Expiration of Miscellaneous Expenses payments.',
            EXPIRATION_CHEQUE_PAYMENTS: 'Expiration of own checks.'
        }
        
    }
});

