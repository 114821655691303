import React from 'react';
import { withStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { LoggedWithNavContainer } from '../../layout';
import { ReportList, OpenedNodesHistoryList, InfoBox, ShortcutLinks } from '../components';
import { Box } from "@material-ui/core";
import { HeaderBreadcrumps } from "@zetti/zweb-react-commons";

const styles = {
  root: {},
  imgContainer: {
    textAlign: 'center',
    marginBottom: 30
  },
  img: {
    height: 80
  }
};

const HomeScreen = ({ classes }) => {
  return (
    <LoggedWithNavContainer>
      <HeaderBreadcrumps breads={["Inicio"]} />
{/*       <div className={classes.imgContainer}>
        <img className={classes.img} src={process.env.PUBLIC_URL + '/images/tysweb.png'} />
      </div> */}
      <Grid container className={classes.root} justify={'center'} style={{paddingBottom: 40}}>
        <Grid item xs={5}>
          <OpenedNodesHistoryList />
        </Grid>
        <Box m={2} />
        <Grid item xs={5}>
          {/*   <ReportList /> */}
          <ShortcutLinks />
          <Grid item xs={5}>
          </Grid>
          <InfoBox />
        </Grid>
      </Grid>
    </LoggedWithNavContainer>
  );
};

export default withStyles(styles)(HomeScreen);


