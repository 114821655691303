import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const styles = {
    root: {
        width: '100%',
        maxWidth: '500px',
        margin: '60px auto auto'
    },
    ImgContainer: {
        flex: 1,
    },
    Img: {
        height: 35,
        margin: 10
    },
    HeaderContainer: {
        display: 'flex',
    },
    HeaderActionContainer: {
        flex: 0,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        paddingRight: 10
    }
};

const LoginBox = ({ classes, children, headerActionComponent }) => {
    return (
        <Paper className={classes.root}>
            <div className={classes.HeaderContainer}>
                <div className={classes.HeaderActionContainer}>{headerActionComponent}</div>
            </div>
            <Divider />
            {children}
        </Paper>
    );
}

LoginBox.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(LoginBox);