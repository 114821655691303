import React from "react";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {createGenerateClassName, StylesProvider, ThemeProvider} from '@material-ui/styles';
import {actions as appActions, MainRouter} from "./modules/app";
import theme from "./createMaterialTheme";
import {Provider} from "react-redux";
import store from "./configureStore";
import {compose, lifecycle} from "recompose";
import "react-perfect-scrollbar/dist/css/styles.css";
import {ZWebAuthenticated} from "./services";

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "ZFR",
  seed: "ZFR"
});

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <StylesProvider injectFirst={false} generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <MainRouter/>
              <ZWebAuthenticated.ToastContainer/>
            </MuiThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </Provider>
    </div>
  );
};
const composer = compose(
  lifecycle({
    componentWillMount() {
      store.dispatch(appActions.bootstrapRequest());
    }
  })
);
export default composer(App);
