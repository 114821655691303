import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('InfoBox', {
    es: {
        support: 'Soporte Técnico',
        next_courses: 'Próximos Cursos',
        video_tutorials: "Videos Tutoriales"
    },
    pt: {     
        support: 'Suporte Técnico',
        next_courses: 'Proximos Cursos',
        video_tutorials: "Vídeos Tutoriais"
    },
    en: {
        support: 'Tech Support',
        next_courses: 'Next Courses',
        video_tutorials: "Video Tutorials"
    }
});

