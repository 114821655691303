import {ZWebAuthenticated} from '../../../../services';

export default ZWebAuthenticated.registerTranslations('OpenNodesHistoryList', {
    es: {
        title: 'Últimos utilizados'
    },
    pt: {
        title: 'Usado pela última vez'
    },
    en: {
        title: 'Last used'
    }
});

