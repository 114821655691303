import { ZWebAuthenticated } from '../../../../services';

export default ZWebAuthenticated.registerTranslations('ReportList', {
    es: {
        title: 'Accesos rápidos'
        },
    pt: {
        title: 'Atalhos'
        },
    en: {
        title: 'Shortcuts'
    }
});

