import {createAction} from 'redux-actions';

const moduleName = 'LOGIN';

export const actionTypes = {
    LOGIN_REQUESTED: `${moduleName}|LOGIN_REQUESTED`,
    LOGIN_SUCCESS: `${moduleName}|LOGIN_SUCCESS`,
    LOGIN_FAILURE: `${moduleName}|LOGIN_FAILURE`,
    LOGOUT_REQUESTED: `${moduleName}|LOGOUT_REQUESTED`,
    LOGOUT_SUCCESS: `${moduleName}|LOGOUT_SUCCESS`,
    LOGOUT_FAILURE: `${moduleName}|LOGOUT_FAILURE`,
};

const login = createAction(actionTypes.LOGIN_REQUESTED, (username, password, redirect) => ({
    username,
    password,
    redirect
}));
const loginSuccess = createAction(actionTypes.LOGIN_SUCCESS);
const loginFailure = createAction(actionTypes.LOGIN_FAILURE);

const logout = createAction(actionTypes.LOGOUT_REQUESTED);
const logoutSuccess = createAction(actionTypes.LOGOUT_SUCCESS);
const logoutFailure = createAction(actionTypes.LOGOUT_FAILURE);

export default {
    login,
    loginSuccess,
    loginFailure,
    logout,
    logoutSuccess,
    logoutFailure,
}
