import React from 'react';
import {ZWebAuthenticated} from '../../../services';
import {withStyles} from "@material-ui/styles";
import LoggedAppBar from "../../ui/components/LoggedAppBar";

const styles = theme => ({
  section: {
    //backgroundColor: theme.palette.custom.backgroundSmoke,
    backgroundColor: "#ffffff",
  }
});

const LoggedContainer = ({children, classes}) => {

  const {LoggedContainer} = ZWebAuthenticated.components;

  return (
      <LoggedContainer>
        <LoggedAppBar/>
        <div className={"section-container fullScreen "+classes.section}>
          {children}
        </div>
      </LoggedContainer>
  );
}

export default withStyles(styles)(LoggedContainer);




