import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {compose, withState, withProps, withHandlers} from "recompose";
import {ZWebAuthenticated} from "../../../../services";
import styles from "./styles";
import t from "./translations";
import {withRouter} from 'react-router-dom';

const withLoginHandling = compose(
    withState("username", "setUsername", null),
    withState("password", "setPassword", null),
    withProps(({username, password}) => {
        return {
            username,
            password,
            valid: username !== null && password !== null
        };
    }),
    withHandlers({
        submit: ({login}) => (username, password, redirect) => {
            login(username, password, redirect);
        }
    })
);



const LoginFormComponent = ({
                                classes,
                                setUsername,
                                setPassword,
                                submit,
                                valid,
                                username,
                                password,
                                loginError,
                                loading,
                                location
                            }) => {

    const usernamePlaceholder = t("username.placeholder", {plainText: true});
    const passwordPlaceholder = t("password.placeholder", {plainText: true});

    // const redirectURL = new URL(location.search, "http://default").searchParams.get("next");
    const searchUrl = location.search + location.hash;
    const redirectURL = searchUrl.split("next=")[1];

    return (
        <div className={classes.FormContainer}>
            <TextField
                className={classes.TextInput}
                autoFocus={true}
                placeholder={usernamePlaceholder}
                label={t("username.label")}
                onChange={event => setUsername(event.target.value)}
            />
            <br/>
            <TextField
                className={classes.TextInput}
                type="password"
                placeholder={passwordPlaceholder}
                label={t("password.label")}
                onChange={event => setPassword(event.target.value)}
                onKeyPress={ev => {
                    if (ev.key === "Enter") {
                        ev.preventDefault();
                        if (valid) {
                            submit(username, password, redirectURL);
                        }
                    }
                }}
            />
            <br/>
            <div className={classes.errorContainer}>
                {loginError && (
                    <ZWebAuthenticated.components.ErrorMsj>
                        {t("wrongCredentials")}
                    </ZWebAuthenticated.components.ErrorMsj>
                )}
            </div>
            <div className={classes.SubmitBtnContainer}>
                <Button
                    className={classes.SubmitBtn}
                    variant="outlined"
                    disabled={!valid || loading}
                    onClick={e => submit(username, password, redirectURL)}
                >
                    {loading && <ZWebAuthenticated.components.Spinner size={20}/>}
                    {!loading && t("loginBtn")}
                </Button>
            </div>
        </div>
    );
};

LoginFormComponent.propTypes = {
    location: PropTypes.object,
    classes: PropTypes.object,
    setUsername: PropTypes.func,
    setPassword: PropTypes.func,
    submit: PropTypes.func,
    valid: PropTypes.bool,
    username: PropTypes.string,
    password: PropTypes.string
};

export default withStyles(styles)(withLoginHandling(ZWebAuthenticated.hocs.WithTranslationRerender(withRouter(LoginFormComponent))));
