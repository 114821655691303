import React from 'react'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import LinkIcon from '@material-ui/icons/Link'
import { compose } from 'recompose'
import { ZWebAuthenticated } from '../../../../services'
import t from './translations'
import styles from './styles';
import { ZWEB_LINKS } from './links';

const composer = compose(
    ZWebAuthenticated.hocs.WithUserLoggedProp,
    ZWebAuthenticated.hocs.WithEntitySelectedProp
)


const ShortcutLinks = ({ userLogged, entitySelected, classes }) => {


    if (!userLogged || !entitySelected) {
        return <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <ZWebAuthenticated.components.Spinner styles={{ flex: 0 }} size={16} />
        </div>
    }
    return (
        <Paper className={classes.paper}>
            <List
                component="nav"
                subheader={<ListSubheader disableSticky={true} component="div" >
                    <LinkIcon style={{ color: '#CCC', position: 'relative', top: 6, marginRight: 10 }} />
                    {t('title')}
                </ListSubheader>}
                className={classes.root}
            >
                {ZWEB_LINKS?.map((link, index) => {
                    return (
                        <ZWebAuthenticated.components.ListItem
                            button
                            className={classes.ListItem}
                            key={index}
                            onClick={() => window.location.href = `${process.env.REACT_APP_SERVER_URL}${link.path}`}
                        >
                            <ListItemText primary={link.moduleName} secondary={null} />
                        </ZWebAuthenticated.components.ListItem>
                    )
                })
                }
            </List>
        </Paper>
    )
}

export default withStyles(styles)(composer(ZWebAuthenticated.hocs.WithTranslationRerender(ShortcutLinks)))
